// third-party
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// project imports
import snackbarReducer from "./slices/snackbar";
import customerReducer from "./slices/customer";
import contactReducer from "./slices/contact";
import productReducer from "./slices/product";
import chatReducer from "./slices/chat";
import calendarReducer from "./slices/calendar";
import mailReducer from "./slices/mail";
import userReducer from "./slices/user";
import cartReducer from "./slices/cart";
import kanbanReducer from "./slices/kanban";
import authApi from "services/auth";
import clientsApi from "services/clients";
import employeesApi from "services/employees";
import debtorsApi from "services/debtors";
import applicationApi from "services/applications";
import dictionaryApi from "services/dictionary";
import signatureApi from "services/signatures";
import contractsApi from "services/contracts";
import enclosuresApi from "services/enclosures";
import attachmentsApi from "services/attachments";
import disbursementApi from "services/disbursements";
import reportApi from "services/report";
import organizationsApi from "services/organizations";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  snackbar: snackbarReducer,
  cart: persistReducer(
    {
      key: "cart",
      storage,
      keyPrefix: "berry-",
    },
    cartReducer
  ),
  kanban: kanbanReducer,
  customer: customerReducer,
  contact: contactReducer,
  product: productReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  mail: mailReducer,
  user: userReducer,
  [authApi.reducerPath]: authApi.reducer,
  [clientsApi.reducerPath]: clientsApi.reducer,
  [employeesApi.reducerPath]: employeesApi.reducer,
  [debtorsApi.reducerPath]: debtorsApi.reducer,
  [applicationApi.reducerPath]: applicationApi.reducer,
  [dictionaryApi.reducerPath]: dictionaryApi.reducer,
  [signatureApi.reducerPath]: signatureApi.reducer,
  [contractsApi.reducerPath]: contractsApi.reducer,
  [enclosuresApi.reducerPath]: enclosuresApi.reducer,
  [attachmentsApi.reducerPath]: attachmentsApi.reducer,
  [disbursementApi.reducerPath]: disbursementApi.reducer,
  [reportApi.reducerPath]: reportApi.reducer,
  [organizationsApi.reducerPath]: organizationsApi.reducer,
});

export default reducer;
